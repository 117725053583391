import computed from './attrs/computed'
import created from './attrs/created'

import Loading from './../Loading/Index'
import Header from './components/Header/Index'

export default {
    name: 'App',
    computed,
    created,
    components: {
        Loading,
        Header
    }
}
