import Vue from 'vue'
import App from './components/App/Index'
import vuetify from './plugins/vuetify'
import VueRouter from 'vue-router'
import Vuex from 'vuex'
import VuexPersistence from 'vuex-persist'
import {createDateFilter} from 'vue-date-fns'
import locale from 'date-fns/locale/pt-BR'
import VueCookie from 'vue-cookie'

import actions from './attrs/actions'
import mutations from './attrs/mutations'
import router from './router/index'

Vue.use(VueRouter)
    .use(Vuex)
    .use(vuetify)
    .use(VueCookie)

Vue.filter('date', createDateFilter("dd MMMM yyyy", {locale}));

const defaultVuexLocal = new VuexPersistence({
    storage: window.sessionStorage
})

const sessionVuexLocal = new VuexPersistence({
    storage: window.localStorage,
    reducer: (state) => ({session: state.session}),
})

const store = new Vuex.Store({
    state: {
        loading: false,
        userLogin: null,
        trip: null,
        installments: {},
        travelers: [],
        order: null,
        pixData: null,
        errors: {},
        cart: {
            accommodations: [],
            optionals: [],
            boardingPlace: null
        },
        session: {
            token: null,
            refreshToken: null,
            user: null
        },
        acceptedTerms: []
    },
    actions,
    mutations,
    plugins: [defaultVuexLocal.plugin, sessionVuexLocal.plugin]
})

router.beforeEach((to, from, next) => {
    if (to.meta.private && (!store.state.session.token || !store.state.session.user)) {
        store.commit('unsetTokens')
        router.push({name: 'Login'})
        return
    }

    next()
})

new Vue({
    router,
    vuetify,
    store,
    render: h => h(App)
}).$mount('#app')
