<template>
    <div id="loading">
        <v-progress-circular
                :size="70"
                :width="7"
                color="#EAC353"
                indeterminate
        ></v-progress-circular>
    </div>
</template>

<script>
    export default {
        name: 'Loading'
    }
</script>
