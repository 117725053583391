import axios from 'axios'

const getApiClient = (store = null) => {
    let api = axios.create({
        baseURL: process.env.VUE_APP_API_URL,
        withCredentials: true
    });

    api.interceptors.request.use(function (config) {
        let token = store.state.session.token
        if (token !== null) {
            config.headers.Authorization = `Bearer ${token}`;
        }

        return config;
    });

    api.interceptors.response.use( (response) => {
        return response;
    }, (error) => {
        if (error.response.status !== 401) {
            return new Promise((resolve, reject) => {
                reject(error);
            });
        }

        if (error.response.status === 401 && ! store.state.session.user?.id) {
            return new Promise((resolve, reject) => {
                reject(error);
            });
        }

        // Try request again with new token
        return store.dispatch('refreshToken')
            .then(() => {
                const config = error.config,
                    token = store.state.session.token

                config.headers['Authorization'] = `Bearer ${token}`

                return new Promise((resolve, reject) => {
                    axios.request(config).then(response => {
                        resolve(response)
                    }).catch((error) => {
                        reject(error)
                    })
                });

            })
            .catch((error) => {
                Promise.reject(error)
            });
    });

    return api
}

export default getApiClient
