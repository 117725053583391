const Accommodations = () => import(/* webpackChunkName: "accommodations" */ '@/components/App/pages/Accommodations/Index')
const Login = () => import(/* webpackChunkName: "login" */ '@/components/App/pages/Login/Index')
const Checkout = () => import(/* webpackChunkName: "checkout" */ '@/components/App/pages/Checkout/Index')
const Payment = () => import(/* webpackChunkName: "complete" */ '@/components/App/pages/Payment/Index')
const Complete = () => import(/* webpackChunkName: "complete" */ '@/components/App/pages/Complete/Index')

import VueRouter from 'vue-router'

const routes = [
    {
        path: '/trip/:id',
        component: Accommodations,
        name: 'Home',
        meta: {
            private: false
        },
        props: {
            default: true,
            keepCart: false
        }
    },
    {
        path: '/login',
        component: Login,
        name: 'Login',
        meta: {
            private: false
        }
    },
    {
        path: '/checkout',
        component: Checkout,
        name: 'Checkout',
        meta: {
            private: true
        }
    },
    {
        path: '/checkout/pay',
        component: Payment,
        name: 'Payment',
        meta: {
            private: true
        }
    },
    {
        path: '/complete',
        component: Complete,
        name: 'Complete',
        meta: {
            private: true
        }
    }
]

const router = new VueRouter({
    routes
})

export default router
