import getApiClient from '../plugins/api-client';

export default {
    checkSession: function(context) {
        return new Promise((resolve, reject) => {
            {
                context.commit('startLoading')
                if (context.session.user && context.session.token) {
                    resolve()
                } else {
                    reject()
                }
            }
        })
    },

    storeUserInfo: function(context) {
        const api = getApiClient(context)

        return new Promise((resolve, reject) => {
            api.get('/users/me')
                .then((response) => {
                    context.commit('setUserData', response.data)
                    resolve(response.data)
                })
                .catch((error) => {
                    reject(error)
                })
        })
    },

    refreshToken: function(context) {
        const api = getApiClient(context)

        let session = context.state.session
        context.commit('setSessionToken', null)

        return new Promise((resolve, reject) => {
            if (session.refreshToken) {
                context.commit('startLoading')
                api.post('auth/refresh', {
                    'refresh_token': session.refreshToken
                })
                    .then((response) => {
                        let data = response.data
                        context.commit('setSessionToken', data.token)
                        context.commit('setSessionRefreshToken', data.refresh_token)
                        resolve(data)
                    })
                    .catch((error) => {
                        context.commit('unsetTokens')
                        reject(error)
                    })
                    .finally(() => {
                        context.commit('stopLoading')
                    })
            } else {
                reject('No token available')
            }
        })
    },

    createCart: function(context, tripId) {
        const api = getApiClient(context)

        return new Promise((resolve, reject) => {
            context.commit('startLoading')
            api.post('carts', {
                trip: `/trips/${tripId}`
            })
                .then((response) => {
                    let cart = response.data
                    context.commit('setCartId', cart['@id'])
                    resolve(cart)
                })
                .catch(error => {
                    reject(error)
                    context.commit('stopLoading')
                })
                .finally(() => {

                })
        })
    },

    updateCart: function(context, data) {
        const api = getApiClient(context),
            cartId = context.state.cart.id

        return new Promise((resolve, reject) => {
            context.commit('startLoading')
            api.patch(cartId, data, {
                headers: {'Content-Type': 'application/merge-patch+json'}
            })
                .then(result => {
                    resolve(result.data)
                })
                .catch(error => {
                    reject(error)
                    context.commit('stopLoading')
                })
                .finally(() => {

                })
        })
    }
}
